<!-- @Author: Yu_Bo date -->
<!-- <trial-expiration ref='dialogTrial' dialogWidth='420px' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
  <div class="zc_dialog_trial_content">
    <img class="img" src="@/assets/images/no_html/no_img_a.png" alt="">
    <div class="text">您的试用已过期</div>
    <div class="tip">如果需要继续使用，请联系我们申请正式使用</div>
  </div>
</trial-expiration> -->
<template>
  <div class='trial_expiration zc_dialog_trial' :style="[allStyle]">
    <el-dialog :visible.sync="dialogVisible" :width="dialogWidth" :show-close='false' :close-on-click-modal='false'>
      <slot></slot>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="cancelShow" class="btnBgColor_grey" size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="affirmShow" class="btnBgColor_blue" size="small" type="primary" @click="dialogVisible = false">联系我们</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      dialogWidth: {//宽度
        type: String,
        defaul: '30%'
      },
      borderRadius: {//圆角
        type: String,
        defaul: '2px'
      },
      cancelShow: {//取消按钮是否显示
        type: Boolean,
        defaul: true
      },
      affirmShow: {//确认按钮是否显示
        type: Boolean,
        defaul: true
      }
    },
    data() {
      return{
        dialogVisible:false
      }
    },
    computed: {
      allStyle() {
        return {
          '--borderRadius': this.borderRadius
        }
      },
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 打开弹框
      openDialogBtn(){
        this.dialogVisible=true
      },
    },
  }
</script>

<style lang='scss' scoped>
  .trial_expiration{
    ::v-deep .el-dialog {
      border-radius: var(--borderRadius);
    }
  }
</style>
